import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_password_reset_link, login } from "../services/api";
import { useStore } from "./Context";

const ForgotPassword = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const [user, setUser] = useState({});
  const [action, setAction] = useState("");

  const actions = {
    reset_password: "RESET_PASSWORD",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.reset_password: {
          setLoading(true);
          get_password_reset_link(user)
            .then((res) => {
              setLoading(false);
              if (res.status) {
                let message = res.message;
                if (message) {
                  toast.success(message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }
              //
            })
            .catch((err) => {
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);
              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  return (
    <div className="h-full w-full mx-auto">
      <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Forgot Password
            </h2>
          </div>

          <ToastContainer />

          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              setAction(actions.reset_password);
            }}
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="my-2">
                <label
                  htmlFor="email-address"
                  className="w-full flex flex-row mb-1 font-bold"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border 
                  border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  autoComplete="off"
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
                <p className="my-1 text-purple-700">
                  <small>
                    A password reset link will be sent to your email after
                    submitting this form
                  </small>
                </p>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                SEND RESET LINK
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
