import { Link } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <div className="w-full flex justify-center items-center my-8 pt-8 flex-wrap pb-20">
        <Link
          to="/new-message"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>New Message</span>
          </div>
        </Link>

        <Link
          to="/phone-book"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
              clipRule="evenodd"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>Phone Book</span>
          </div>
        </Link>

        <Link
          to="/transactions"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>Transactions</span>
          </div>
        </Link>

        <Link
          to="/messages"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>Messages</span>
          </div>
        </Link>

        <Link
          to="/profile"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>Profile</span>
          </div>
        </Link>

        <Link
          to="/deposit"
          className="md:w-1/5 xs:w-1/3 m-4 h-28 rounded border hover:border-purple-700 flex flex-col items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-1/2 w-1/2 text-purple-700 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <div className="flex justify-center items-center my-2 text-purple-700 font-bold">
            <span>Top Up</span>
          </div>
        </Link>
      </div>
      <Footer />

    </>
  );
};

export default Home;
