import { useEffect, useState } from "react";
import { contact_support } from "../services/api";
import { isObject } from "lodash";
import { Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "./Context";

const ContactUs = () => {
  const { setLoading } = useStore();
  const [postData, setPostData] = useState({});
  const [action, setAction] = useState(null);

  const actions = {
    contactSupport: "CONTACT_SUPPORT",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.contactSupport: {
          setLoading(true);
          contact_support(postData)
            .then((res) => {
              if (res.status) {
                let message = res.message;
                if (message) {
                  toast.success(message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                //console.log(res)
                setPostData({});
                setLoading(false);
                //props.history.push("/dashboard");
              }
              //
            })
            .catch((err) => {
              //console.log(JSON.stringify(err));
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);

              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  return (
    <>
      <ToastContainer />
      {/* Large devices */}
      <div className="lg:flex hidden flex-col justify-center items-center bg-gray-100 absolute top-0  right-0 left-0 bottom-0 -z-1 mx-8 mt-20 rounded ">
        <div className="flex absolute top-8 left-28 right-28 bottom-8 bg-white">
          <div className="absolute flex z-10 w-1/2 right-0  bg-purple-800 self-center">
            <div className="flex flex-col text-gray-200 text-sm m-10">
              <h2 className="text-2xl font-bold">Info</h2>
              <div className="mt-5 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="mx-2">support@textug.com</span>
              </div>
              <div className="mt-5 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span className="mx-2">+256 200902091 / +256 702771124</span>
              </div>
              <div className="mt-5 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
                <span className="mx-2">
                  Plot 112 Bukoto Street, Kololo Kampala, Uganda
                </span>
              </div>
            </div>
          </div>

          <div className="bg-white w-4/5 h-full">
            <div className="w-1/2 h-full pl-20 pt-10  flex flex-col">
              <h2 className="text-3xl font-bold">Contact Us</h2>
              <p className="mt-4 text-gray-500 text-sm">
                Feel free to contact us anytime. We will get back to you as soon
                as we can!
              </p>
              <form
                className="flex flex-col"
                onSubmit={(e) => {
                  e.preventDefault();
                  setAction(actions.contactSupport);
                }}
              >
                <div className="flex flex-col mt-4">
                  <label
                    className="text-gray-500 text-sm sr-only"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    required
                    placeholder="Name"
                    className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                    name="name"
                    type="text"
                    value={postData.name || ''}
                    onChange={(e) =>
                      setPostData({ ...postData, name: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    className="text-gray-500 text-sm sr-only"
                    htmlFor="name"
                  >
                    Email
                  </label>
                  <input
                    required
                    placeholder="Email"
                    className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                    name="email"
                    type="email"
                    value={postData.email || ''}
                    onChange={(e) =>
                      setPostData({ ...postData, email: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    className="text-gray-500 text-sm sr-only"
                    htmlFor="subject"
                  >
                    Subject
                  </label>
                  <input
                    required
                    placeholder="subject"
                    className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                    name="name"
                    type="text"
                    value={postData.subject || ''}
                    onChange={(e) =>
                      setPostData({ ...postData, subject: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    className="text-gray-500 text-sm sr-only"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <textarea
                    required
                    rows="1"
                    placeholder="Message"
                    className="border-0 border-b  outline-white  focus:outline-none focus:ring-transparent resize-none overflow-y-auto"
                    name="message"
                    value={postData.message || ''}
                    onChange={(e) =>
                      setPostData({ ...postData, message: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <button className="py-2 px-4 text-sm font-bold text-white tracking-widest bg-purple-700 hover:bg-purple-500">
                    SEND
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-gray-300 w-1/5 h-full"></div>
        </div>
      </div>

      {/* Small devices */}
      <div className="lg:hidden flex flex-col items-center bg-gray-100 absolute top-0  right-0 left-0 bottom-0 -z-1 mx-auto mt-20 rounded ">
        <div className="bg-white py-5">
          <div className=" px-10 flex flex-col">
            <h2 className="text-3xl font-bold">Contact Us</h2>
            <p className="mt-4 text-gray-500 text-sm">
              Feel free to contact us anytime. We will get back to you as soon
              as we can!
            </p>
            <form
              className="flex flex-col"
              onSubmit={(e) => {
                e.preventDefault();
                setAction(actions.contactSupport);
              }}
            >
              <div className="flex flex-col mt-4">
                <label className="text-gray-500 text-sm sr-only" htmlFor="name">
                  Name
                </label>
                <input
                  required
                  placeholder="Name"
                  className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                  name="name"
                  type="text"
                  value={postData.name || ''}
                  onChange={(e) =>
                    setPostData({ ...postData, name: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-4">
                <label
                  className="text-gray-500 text-sm sr-only"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  required
                  placeholder="Email"
                  className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                  name="email"
                  type="email"
                  value={postData.email || ''}
                  onChange={(e) =>
                    setPostData({ ...postData, email: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-4">
                  <label
                    className="text-gray-500 text-sm sr-only"
                    htmlFor="subject"
                  >
                    Subject
                  </label>
                  <input
                    required
                    placeholder="subject"
                    className="border-0 border-b outline-white  focus:outline-none focus:ring-transparent"
                    name="name"
                    type="text"
                    value={postData.subject || ''}
                    onChange={(e) =>
                      setPostData({ ...postData, subject: e.target.value })
                    }
                  />
                </div>
              <div className="flex flex-col mt-4">
                <label
                  className="text-gray-500 text-sm sr-only"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  required
                  rows="2"
                  placeholder="Message"
                  className="border-0 border-b  outline-white  focus:outline-none focus:ring-transparent resize-none overflow-y-auto"
                  name="message"
                  value={postData.message || ''}
                  onChange={(e) =>
                    setPostData({ ...postData, message: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-4">
                <button className="py-2 px-4 text-sm font-bold text-white tracking-widest bg-purple-700 hover:bg-purple-500">
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className=" flex my-10  bg-purple-800">
          <div className="flex flex-col text-gray-200 text-sm p-6">
            <h2 className="text-2xl font-bold">Info</h2>
            <div className="mt-5 flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <span className="mx-2">support@textug.com</span>
            </div>
            <div className="mt-5 flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span className="mx-2">+256 200902091 / +256 702771124</span>
            </div>
            <div className="mt-5 flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
              <span className="mx-2">
                Plot 112 Bukoto Street, Kololo Kampala, Uganda
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
