import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isObject } from "lodash";
import { send_sms } from "../services/api";
import { useStore } from "./Context";
import SelectContact from "./SelectContact";
import NewMessageSuccessModal from "./NewMessageSuccessModal";
import SmsCounter from "@marcinkowalczyk/sms-counter";

const NewMessage = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const [activeTab, setActiveTab] = useState("enterNumber");
  const [selectContact, setSelectContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");
  const [inputData, setInputData] = useState({});
  const [action, setAction] = useState("");
  const [success, setSuccess] = useState(false);
  const [cost, setCost] = useState("");
  const [smsCount, setSmsCount] = useState({});
  const forwardedMessage = props.location.state;

  const actions = {
    sendSMS: "SEND_SMS",
  };

  useEffect(() => {
    if (selectedContact) {
      setInputData({ ...inputData, recipients: selectedContact.phone_numbers });
    }
  }, [selectedContact]);

  useEffect(() => {
    if (forwardedMessage) {
      setInputData({
        ...inputData,
        recipients: forwardedMessage.recipients,
        message: forwardedMessage.text,
      });
    }
  }, [forwardedMessage]);

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.sendSMS: {
          setLoading(true);
          let data = { ...inputData };
          if (!data.recipients) {
            data.contact_id = selectedContact.id;
          }

          send_sms(app_state.auth_token, data)
            .then((res) => {
              setLoading(false);
              if (res.cost) {
                setAppState({ ...app_state, refresh: new Object() });
                setCost(res.cost);
                setSuccess(true);
              }
              //
            })
            .catch((err) => {
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);
              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  const tab_enter_number_classes =
    activeTab === "enterNumber"
      ? "border-purple-700 text-purple-700 font-bold"
      : "";
  const tab_phone_book_classes =
    activeTab === "phoneBook"
      ? "border-purple-700 text-purple-700 font-bold"
      : "";

  if (success) {
    return (
      <NewMessageSuccessModal
        isOpen={success}
        setIsOpen={setSuccess}
        messageCost={cost}
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setAction(actions.sendSMS);
      }}
    >
      <ToastContainer />
      <div className=" py-4 flex-wrap shadow rounded my-10 sm:mx-16 md:mx-44 bg-gray-50">
        <div className="w-full my-2 mx-4">
          <span className="font-bold text-lg">Send SMS</span>
        </div>

        <div className="mx-4 bg-white rounded">
          <div className="w-full ml-4 pt-4">
            <span className="">Send Message to :</span>
          </div>

          <div className="flex mb-2">
            <div
              className={`ml-4 pr-2 flex items-center my-1 rounded border-b-4 cursor-pointer ${tab_enter_number_classes}`}
              onClick={() => setActiveTab("enterNumber")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </span>
              <span><small>Enter Number</small></span>
            </div>
            <div
              className={`flex my-1 items-center  border-b-4 pl-3 cursor-pointer rounded ${tab_phone_book_classes}`}
              onClick={() => {
                setActiveTab("phoneBook");
                setSelectContact(true);
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                  />
                </svg>
              </span>
              <span><small>Select from phone-book</small> </span>
            </div>
          </div>

          <div className="mx-4 rounded">
            <textarea
              required
              placeholder="Enter Numbers seperated by ',' E.g 0787113924,0703436574"
              cols="8"
              className="w-full h-full my-2 border border-gray-300 focus:border-purple-700 rounded"
              value={inputData.recipients || ""}
              onChange={(e) => {
                setInputData({ ...inputData, recipients: e.target.value });
              }}
            ></textarea>
          </div>

          <div className="mx-4 rounded my-4">
            <label htmlFor="message_body" className="my-1">
              Your Message
            </label>
            <div className="w-full flex flex-row-reverse text-xs">
              {smsCount.remaining >= 0 && (
                <span>Remaining characters: {smsCount.remaining}</span>
              )}
              {smsCount.messages > 0 && (
                <span className="mx-2">
                  Messages count: {smsCount.messages}
                </span>
              )}
            </div>
            <textarea
              required
              name="message_body"
              placeholder="Type your message here..."
              cols="8"
              className="w-full h-full my-2 border border-gray-300 focus:border-purple-700 rounded"
              value={inputData.message || ""}
              onChange={(e) => {
                setInputData({ ...inputData, message: e.target.value });
                setSmsCount(SmsCounter.count(e.target.value));
              }}
            ></textarea>
          </div>

          <div className="flex flex-row-reverse mx-4 pb-4">
            <button
              type="submit"
              className="px-6 py-2 font-bold  rounded bg-purple-700 hover:bg-purple-500 text-white my-2"
            >
              SEND
            </button>
          </div>
        </div>
      </div>
      <SelectContact
        open={selectContact}
        setOpen={setSelectContact}
        setSelectedContact={setSelectedContact}
      />
    </form>
  );
};

export default NewMessage;
