import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";

const ViewMessage = (props) => {
  const { isOpen, setIsOpen, message } = props;
  const messageBody = useRef(null);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <>
                  <div className="h-full w-full mx-auto">
                    <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
                      <div className="max-w-md w-full space-y-8">
                        <div>
                          <h3 className="mt-6 text-center text-xl font-bold text-gray-900">
                            Message
                          </h3>
                        </div>

                        <div className="rounded-md shadow-sm -space-y-px">
                          <div className="">
                            <div
                              htmlFor="name"
                              className="w-full flex flex-row mb-1 font-bold"
                            >
                              Sent to:
                            </div>
                            <div>{message.recipients}</div>
                          </div>
                          <div className="my-2">
                            <div className="w-full flex flex-row mb-1 font-bold">
                              Message Body
                            </div>

                            <textarea
                              ref={messageBody}
                              cols="8"
                              className="w-full h-full my-2 border-0  focus:border-0 outline-none focus:outline-none rounded"
                              value={message.text}
                              readOnly={true}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default ViewMessage;
