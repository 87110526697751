import { DisappearedLoading	 } from "react-loadingg";

const Loading = () => {
  return (
    <div className="w-full h-full fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-20">
      <DisappearedLoading size="large" color="#fff" />
    </div>
  );
};

export default Loading;
