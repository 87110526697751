import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { get_transactions } from "../services/api";
import curr_format from "../utils/currency_formatter";
import { useStore } from "./Context";

const Transactions = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const { transactions } = app_state;

  useEffect(() => {
    setLoading(true);
    get_transactions(app_state.auth_token)
      .then((response) => {
        setLoading(false);
        if (response.transactions) {
          setAppState({
            ...app_state,
            transactions: [...response.transactions.reverse()],
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  }, []);

  if (!transactions.length) {
    return (
      <div className="w-4/6 py-20 mx-auto my-10  flex flex-col justify-center items-center bg-gray-100 rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-purple-700 text-2xl"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
        <span className="my-4">You have no transactions</span>
        <button
          onClick={() => props.history.push("/deposit")}
          className="px-4 py-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
        >
          Top Up
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pb-28">
      <div className="">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="flex flex-row-reverse my-4">
              <button
                onClick={() => props.history.push("/deposit")}
                className="px-4 py-2 mx-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
              >
                Top Up
              </button>
            </div>

            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    #ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Narrative
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {index + 1}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {transaction.narrative}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        title="View recipients"
                        className="px-4 inline-flex text-xs leading-5 cursor-pointer border hover:border-purple-700 font-semibold rounded-full bg-purple-100 text-purple-800"
                      >
                        {curr_format(transaction.amount)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <span>{transaction.date_created}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {transaction.type == "DEBIT" && (
                        <span className="px-2 border rounded-full">DEBIT</span>
                      )}
                      {transaction.type == "DEPOSIT" && (
                        <span className="px-2 text-purple-800 rounded-full">
                          DEPOSIT
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Transactions);
