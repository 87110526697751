import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signup } from "../services/api";
import { useStore } from "./Context";
import NotificationModal from "./NotificationModal";

const SignupSuccess = (props) => {
  return (
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
            <CheckIcon className="h-6 w-6 text-green-60 rounded-full" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              You have signed up successfully !!
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Login to continue</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => props.history.push("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
};

const Signup = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const [user, setUser] = useState({});
  const [action, setAction] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const actions = {
    signup: "SIGNUP",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.signup: {
          setLoading(true);

          signup(user)
            .then((res) => {
              setLoading(false);
              return setOpenModal(true);

              //
            })
            .catch((err) => {
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);

              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  return (
    <>
      {openModal && (
        <NotificationModal open={openModal} setOpen={setOpenModal}>
          <SignupSuccess {...props} />
        </NotificationModal>
      )}

      <div className="h-full w-full mx-auto">
        <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-1">
            <div>
              <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                Sign up
              </h2>
            </div>

            <ToastContainer />

            <form
              className="mt-4 space-y-4 xs:pb-20 md:pb-16 lg:pb-0"
              onSubmit={(e) => {
                e.preventDefault();
                console.log("action => signup");
                setAction(actions.signup);
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div className="flex w-full my-2 justify-between flex-wrap">
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="firstname"
                      className="w-full flex flex-row my-1"
                    >
                      First name
                    </label>
                    <input
                      id="firstname"
                      name="firstname"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="First name"
                      onChange={(e) => {
                        setUser({ ...user, firstname: e.target.value });
                      }}
                    />
                  </div>
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="lastname"
                      className="w-full flex flex-row my-1"
                    >
                      Last name
                    </label>
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Last name"
                      onChange={(e) => {
                        setUser({ ...user, lastname: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="flex w-full my-2 place-content-between flex-wrap">
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="email-address"
                      className="w-full flex flex-row my-1"
                    >
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="mobile_number"
                      className="w-full flex flex-row my-1"
                    >
                      Mobile number
                    </label>
                    <input
                      id="mobile_number"
                      name="mobile_number"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="+256777777777"
                      onChange={(e) => {
                        setUser({ ...user, mobile_number: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="flex w-full my-2 place-content-between flex-wrap">
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="password"
                      className="w-full flex flex-row my-1"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="off"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border
                     border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                     focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Password"
                      minLength="6"
                      onChange={(e) => {
                        setUser({ ...user, password: e.target.value });
                      }}
                    />
                  </div>
                  <div className="xs:w-full md:w-max">
                    <label
                      htmlFor="password"
                      className="w-full flex flex-row my-1"
                    >
                      Confirm Password
                    </label>
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type="password"
                      autoComplete="off"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border
                     border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                     focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Confirm Password"
                      minLength="6"
                      onChange={(e) => {
                        setUser({ ...user, confirm_password: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign up
                </button>
              </div>
              <div className="text-sm flex place-content-center">
                <span>Already registered? &nbsp;</span>
                <a
                  href="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Login
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Signup);
