import { Dialog, Transition } from "@headlessui/react";
import { isObject } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { create_contact, update_contact } from "../services/api";
import { useStore } from "./Context";

const CreateContact = (props) => {
  const { app_state, setLoading } = useStore();
  const { isOpen, setIsOpen, setRefreshContacts, editData, setEditData, toast } = props;
  const [data, setData] = useState({});
  const [action, setAction] = useState("");

  const actions = {
    createContact: "CREATE_CONTACT",
    updateContact: "UPDATE_CONTACT",
  };

  useEffect(() => {
    if (isObject(editData)) {
      setData(editData);
    }
  }, [editData]);

  useEffect(() => {
    switch (action) {
      case actions.updateContact: {
        setLoading(true);
        update_contact(app_state.auth_token, data, editData.id)
          .then((res) => {
            setLoading(false);
            let message = res.message;
            if (message) {
              toast.success(message, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            setEditData(false);
            setData({});
            setIsOpen(false);
            setRefreshContacts(new Object());

            //
          })
          .catch((err) => {
            let message = err.response.data.message;
            let toast_config = {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            };

            if (isObject(message)) {
              let messages = [];
              Object.keys(message).forEach((key) => {
                messages.push(message[key]);
              });
              //toast(messages.join(' , '));
              toast.error(messages.join(" , "), toast_config);
            } else {
              toast.error(message, toast_config);
            }
            console.log(err.response.data);
            setLoading(false);
          });
        break;
      }
      case actions.createContact: {
        setLoading(true);
        create_contact(app_state.auth_token, data)
          .then((res) => {
            setLoading(false);
            let message = res.message;
            if (message) {
              toast.success(message, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            setIsOpen(false);
            setRefreshContacts(new Object());

            //
          })
          .catch((err) => {
            let message = err.response.data.message;
            let toast_config = {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            };

            if (isObject(message)) {
              let messages = [];
              Object.keys(message).forEach((key) => {
                messages.push(message[key]);
              });
              //toast(messages.join(' , '));
              toast.error(messages.join(" , "), toast_config);
            } else {
              toast.error(message, toast_config);
            }
            console.log(err.response.data);
            setLoading(false);
          });
          break;
      }
      default: {
        setAction(null);
      }
    }
  }, [action]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <>
                  <div className="h-full w-full mx-auto">
                    <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
                      <div className="max-w-md w-full space-y-8">
                        <div>
                          <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
                            {editData ? "Edit Contact" : "New Contact"}
                          </h2>
                        </div>

                        <form
                          className="mt-8 space-y-6"
                          onSubmit={(e) => {
                            e.preventDefault();

                            if (editData) {
                               setAction(actions.updateContact);
                            } else {
                              setAction(actions.createContact);
                            }
                          }}
                        >
                          <div className="rounded-md shadow-sm -space-y-px">
                            <div className="my-2">
                              <label
                                htmlFor="name"
                                className="w-full flex flex-row mb-1 font-bold"
                              >
                                Contact Name
                              </label>
                              <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                className="appearance-none rounded relative block w-full px-3 py-2 border 
                  border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Contact name"
                                autoComplete="off"
                                value={data.name || ""}
                                onChange={(e) => {
                                  setData({ ...data, name: e.target.value });
                                }}
                              />
                            </div>
                            <div className="my-2">
                              <label
                                htmlFor="phone_numbers"
                                className="w-full flex flex-row mb-1 font-bold"
                              >
                                Phone number(s)
                              </label>

                              <textarea
                                name="phone_numbers"
                                placeholder="Enter Numbers sperated by ',' E.g 0798765454,0778765454"
                                cols="8"
                                className="w-full h-full my-2 border border-gray-300 focus:border-purple-700 rounded"
                                value={data.phone_numbers || ""}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    phone_numbers: e.target.value,
                                  });
                                }}
                              ></textarea>
                            </div>
                          </div>

                          <div className="">
                            <button
                              type="submit"
                              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Save
                            </button>
                          </div>
                          <div className="pb-10">
                            <button
                              type="button"
                              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-gray-300 focus:outline-none hover:bg-gray-200 hover:text-gray-600"
                              onClick={() => setIsOpen(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default withRouter(CreateContact);
