import { useEffect, useState } from "react";
import { delete_contact, get_user_contacts } from "../services/api";
import { useStore } from "./Context";
import { isObject } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateContact from "./CreateContact";

export default function PhoneBook() {
  const { app_state, setAppState, setLoading } = useStore();
  const { contacts, user } = app_state;
  const [createNewContact, setCreateNewContact] = useState(false);
  const [editData, setEditData] = useState(false);
  const [deleteContact, setDeleteContact] = useState(false);
  const [refreshContacts, setRefreshContacts] = useState(null);


  useEffect(() => {
    if (isObject(deleteContact)) {
      setLoading(true);
      delete_contact(app_state.auth_token, deleteContact.id)
        .then((res) => {
          setLoading(false);
          let message = res.message;
          if (message) {
            toast.success(message, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return setRefreshContacts(new Object());
        })
        .catch((err) => {
          let message = err.response.data.message;
          let toast_config = {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          };

          if (isObject(message)) {
            let messages = [];
            Object.keys(message).forEach((key) => {
              messages.push(message[key]);
            });
            //toast(messages.join(' , '));
            toast.error(messages.join(" , "), toast_config);
          } else {
            toast.error(message, toast_config);
          }
          console.log(err.response.data);
          setLoading(false);
        });
    }
  }, [deleteContact]);

  useEffect(() => {
    setLoading(true);
    get_user_contacts(app_state.auth_token)
      .then((res) => {
        setLoading(false);
        if (res.contacts) {
          setAppState({ ...app_state, contacts: res.contacts });
        }
        //
      })
      .catch((err) => {
        let message = err.response.data.message;
        let toast_config = {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };

        if (isObject(message)) {
          let messages = [];
          Object.keys(message).forEach((key) => {
            messages.push(message[key]);
          });
          //toast(messages.join(' , '));
          toast.error(messages.join(" , "), toast_config);
        } else {
          toast.error(message, toast_config);
        }
        console.log(err.response.data);
        setLoading(false);
      });
  }, [refreshContacts]);

  if (!contacts.length) {
    return (
      <div className="flex flex-col w-screen">
        <CreateContact
          isOpen={createNewContact}
          setIsOpen={setCreateNewContact}
          toast={toast}
          setRefreshContacts={setRefreshContacts}
          editData={editData}
          setEditData={setEditData}
        />
        <ToastContainer />
        <div className="w-4/6 py-20 mx-auto my-4  flex flex-col justify-center items-center bg-gray-100 rounded">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 text-purple-700 text-2xl"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <span className="my-4">You have not added any contact</span>
          <button
            onClick={() => setCreateNewContact(true)}
            className="px-4 py-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
          >
            ADD NEW CONTACT
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col pb-28">
      <ToastContainer />
      <CreateContact
        isOpen={createNewContact}
        setIsOpen={setCreateNewContact}
        toast={toast}
        setRefreshContacts={setRefreshContacts}
        editData={editData}
        setEditData={setEditData}
      />

      <div className="flex  mx-auto py-2  ">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="flex flex-row-reverse my-4">
            <button
              onClick={() => setCreateNewContact(true)}
              className="px-4 py-2 mx-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
            >
              NEW CONTACT
            </button>
          </div>
          <table className="divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  #ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Contact Name
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Number of contacts
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {contacts.map((contact, index) => (
                <tr key={index}>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {index + 1}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {contact.name}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                      {contact.phone_numbers.split(",").length}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <a
                      href="#"
                      className="text-purple-600 hover:text-purple-900 border rounded px-2 py-1"
                      onClick={() => {
                        setEditData(contact);
                        setCreateNewContact(true);
                      }}
                    >
                      Edit
                    </a>
                    <a
                      href="#"
                      className="text-red-600 hover:text-red-900 ml-2 border rounded px-2 py-1"
                      onClick={() => {
                        setDeleteContact(contact)
                      }}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
