import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { verify_token } from "../services/api";

const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
  const [app_state, setAppState] = useState({
    isAuthenticated: false,
    user: null,
    contacts: [],
    transactions: [],
    messages: [],
    refresh: false,
  });

  const [loading, setLoading] = useState(false);

  let store = { app_state, setAppState, loading, setLoading };

  useEffect(() => {
    let auth_token = localStorage.getItem("auth_token");
    if (auth_token) {
      const { user } = jwt_decode(auth_token);
      setLoading(true);
      verify_token(auth_token)
        .then((response) => {
          setLoading(false);
          if (response.isValid) {
            setAppState({
              ...app_state,
              isAuthenticated: true,
              user: response.user,
              auth_token: auth_token,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);
          //console.log(err.response.data.message);
        });
    }
  }, [app_state.refresh]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(StoreContext);

  return store;
};
