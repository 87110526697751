import { PreviousMap } from "postcss";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { get_messages } from "../services/api";
import { useStore } from "./Context";
import ViewMessage from "./ViewMessage";

const Messages = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const { messages } = app_state;
  const [viewMessage, setViewMessage] = useState({});
  const [openViewMessage, setOpenViewMessage] = useState(false);

  useEffect(() => {
    setLoading(true);
    get_messages(app_state.auth_token)
      .then((res) => {
        setLoading(false);
        if (res.messages) {
          setAppState({ ...app_state, messages: [...res.messages.reverse()] });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        //console.log(err.response.data);
      });
  }, []);

  if (!messages.length) {
    return (
      <div className="w-4/6 py-20 mx-auto my-10  flex flex-col justify-center items-center bg-gray-100 rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-purple-700 text-2xl"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
        <span className="my-4">You have not sent any messages</span>
        <button
          onClick={() => props.history.push("/new-message")}
          className="px-4 py-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
        >
          New Message
        </button>
      </div>
    );
  }

  return (
    <>
      <ViewMessage
        isOpen={openViewMessage}
        setIsOpen={setOpenViewMessage}
        message={viewMessage}
      />

      <div className="flex flex-col items-center pb-28">
        <div className="">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <div className="flex flex-row-reverse my-4">
                <button
                  onClick={() => props.history.push("/new-message")}
                  className="px-4 py-2 mx-2 border border-purple-700 font-semibold text-purple-700 hover:bg-purple-700 hover:text-white rounded"
                >
                  New Message
                </button>
              </div>

              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Message
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Recipient(s)
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cost
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date created
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {messages.map((message, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {index + 1}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 w-1/3">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {`${message.text.substring(0, 20)} `}
                              <span title="View message details">
                                <button
                                  onClick={() => {
                                    setViewMessage(message);
                                    setOpenViewMessage(true);
                                  }}
                                  className="mx-1 border rounded-full text-xs px-1 font-bold text-purple-700 hover:border-purple-700"
                                >
                                  view
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          title="View recipients"
                          className="px-4 inline-flex text-xs leading-5 cursor-pointer border hover:border-purple-700 font-semibold rounded-full bg-purple-100 text-purple-800"
                        >
                          {message.recipients
                            ? message.recipients.split(",").length
                            : ""}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <span>{message.cost}</span>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <span>{message.date_created}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        {message.status == 2 && (
                          <span className="px-2 bg-green-300 rounded-full">
                            sent
                          </span>
                        )}
                        {message.status == 1 && (
                          <span className="px-2 bg-purple-100 text-purple-800 rounded-full">
                            pending
                          </span>
                        )}
                        {message.status == 3 && (
                          <span className="px-2 bg-red-400  rounded-full">
                            failed
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <a
                          href="#"
                          className="text-purple-600 hover:text-purple-900 border rounded px-2 py-1"
                          onClick={() => {
                            props.history.push('/new-message', { ...message })
                          }}
                        >
                          Forward
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Messages);
