import axios from "axios";

let base_url = "https://api.textug.com";

const signup = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/signup`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const login = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/login`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_api_key = (auth_token, userId) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `api-key/new/${userId}`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, {}, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const update_user = (auth_token, data, userId) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `user/update/${userId}`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_password_reset_link = (data) => {
  
  let path = `forgot-password`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_user_contacts = (auth_token) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `phone-book/user/contacts`;

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/${path}`, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const deposit = (auth_token, data) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `transaction/deposit`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const verify_token = (auth_token) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `verify/token`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, {}, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const refresh_balance = (auth_token) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `account-balance/refresh`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, {}, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_transactions = (auth_token) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `transactions/user`;

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/${path}`, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const create_contact = (auth_token, data) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `phone-book/contact/create`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const update_contact = (auth_token, data, id) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `phone-book/contact/edit/${id}`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const delete_contact = (auth_token, id) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `phone-book/contact/delete/${id}`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, {}, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_messages = (auth_token) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `messages/user`;

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/${path}`, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const send_sms = (auth_token, data) => {
  const headers = {
    Authorization: `Bearer ${auth_token}`,
  };
  let path = `sms/send`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};
const contact_support = (data) => {
  
  let path = `contact_support`;

  return new Promise((resolve, reject) => {
    axios
      .post(`${base_url}/${path}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export {
  signup,
  login,
  get_api_key,
  update_user,
  get_password_reset_link,
  get_user_contacts,
  deposit,
  verify_token,
  refresh_balance,
  get_transactions,
  create_contact,
  update_contact,
  delete_contact,
  get_messages,
  send_sms,
  contact_support,
};
