import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../services/api";
import { useStore } from "./Context";

const Login = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const [user, setUser] = useState({});
  const [action, setAction] = useState("");

  const actions = {
    login: "LOGIN",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.login: {
          setLoading(true);
          login(user)
            .then((res) => {
              if (res.status) {
                let message = res.message;
                if (message) {
                  toast.success(message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                //console.log(res);

                localStorage.setItem("auth_token", res.auth_token);

                setAppState({
                  ...app_state,
                  isAuthenticated: true,
                  user: res.user,
                  auth_token: res.auth_token,
                });

                
                setLoading(false);
                props.history.push("/dashboard");
              }
              //
            })
            .catch((err) => {
              //console.log(JSON.stringify(err));
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);

              setLoading(false);

            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  return (
    <div className="h-full w-full mx-auto">
      <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in
            </h2>
          </div>

          <ToastContainer />

          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              setAction(actions.login);
            }}
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="my-2 lg:my-1">
                <label
                  htmlFor="email-address"
                  className="w-full flex flex-row mb-1 font-bold"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border 
                  border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  autoComplete="off"
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
              </div>
              <div className="my-2 lg:my-1">
                <label
                  htmlFor="password"
                  className="w-full flex flex-row mb-1 font-bold"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border
                   border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                   focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <a
                  href="/forgot-password"
                  className="font-normal text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-normal rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
            <div className="text-sm flex place-content-center xs:mb-48 xs:pb-28 md:mb-48 md:pb-28 lg:mb-2 lg:pb-2">
              <span>Not registered? &nbsp;</span>
              <a
                href="/signup"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Signup
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
