const Footer = () => {
  return (
    <div className="flex w-full justify-center items-center flex-wrap fixed z-10 bottom-0 pb-2 pt-1 right-0 left-0 text-purple-500 bg-white">
      <div className="flex justify-start items-center">
        <span className="text-gray-700 flex justify-center items-center text-sm">From </span>
        <a
          className="mx-2 hover:text-purple-900"
          href="https://gmtconsults.com"
        >
          <img className="flex justify-center items-center" style={{width: '140px', height: 'auto'}} src="/gmt-logo.png" alt="GMT Consults LTD" />
        </a>
      </div>

      <div className="flex items-center xs:justify-center xs:w-full md:w-max">
        <span className="text-gray-600 mx-1 xs:hidden md:flex"> | </span>
        <small>
          <span className="text-gray-700">Other products </span>
          <a className="mx-1 hover:text-purple-900" href="https://efinanci.com">
            <span className="text-gray-600"> : </span> efinanci
          </a>
        </small>

        <a className="mx-1 hover:text-purple-900" href="https://sentepay.com">
          <small>
            <span className="text-gray-600"> | </span>sentepay
          </small>
        </a>
        <a className="mx-1 hover:text-purple-900" href="https://bondstock.ug">
          <small>
            <span className="text-gray-600"> | </span>bondstock.ug
          </small>
        </a>
      </div>
    </div>
  );
};

export default Footer;
