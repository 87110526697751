/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useStore } from "./Context";
import { isObject } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_user_contacts } from "../services/api";

export default function SelectContact({ open, setOpen, setSelectedContact }) {
  const searchInputRef = useRef(null);
  const { app_state, setAppState } = useStore();
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm) {
      let filteredContacts = contacts.filter((contact) => {
        return contact.name.toLowerCase().includes(searchTerm);
      });

      setContacts([...filteredContacts]);
    } else {
      setContacts(app_state.contacts);
    }
  }, [searchTerm]);

  useEffect(() => {
    setContacts(app_state.contacts);
  }, []);

  useEffect(() => {
    get_user_contacts(app_state.auth_token)
      .then((res) => {
        if (res.contacts) {
          setAppState({ ...app_state, contacts: res.contacts });
        }
        //
      })
      .catch((err) => {
        let message = err.response.data.message;
        let toast_config = {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };

        if (isObject(message)) {
          let messages = [];
          Object.keys(message).forEach((key) => {
            messages.push(message[key]);
          });
          //toast(messages.join(' , '));
          toast.error(messages.join(" , "), toast_config);
        } else {
          toast.error(message, toast_config);
        }
        console.log(err.response.data);
      });
  }, []);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen sm:px-1 md:px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <>
                  <ToastContainer />
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="flex flex-col flex-wrap">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="flex w-full justify-center">
                          <input
                            ref={searchInputRef}
                            className="rounded w-5/6 shadow border-0 bg-gray-50 focus:outline-none focus:border-0"
                            type="text"
                            placeholder="search contact by name"
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                          />
                        </div>
                        <div className="flex w-full h-52 pt-10 flex-col items-center overflow-y-scroll">
                          {contacts.map((contact, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between w-5/6 mx-4 text-sm uppercase text-gray-500 my-0.5 rounded shadow  py-2"
                              >
                                <span className="px-8">{contact.name}</span>

                                <span className="mx-4 lowercase">
                                  <button
                                    className="text-purple-700 border hover:border-purple-700 rounded px-2"
                                    onClick={() => {
                                      setSelectedContact(contact);
                                      setOpen(false);
                                    }}
                                  >
                                    select
                                  </button>
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
