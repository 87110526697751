import "./App.css";
import Login from "./components/Login";
import styled from "styled-components";
import Signup from "./components/Signup";
import Home from "./components/Home";
import TopBar from "./components/TopBar";
import PhoneBook from "./components/PhoneBook";
import { Route, Switch, withRouter } from "react-router-dom";
import NewMessage from "./components/NewMessage";
import Messages from "./components/Messages";
import Transactions from "./components/Transactions";
import { useStore } from "./components/Context";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import Deposit from "./components/Deposit";
import ForgotPassword from "./components/ForgotPassword";
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";

const CurvedHero = styled.div`
  margin: 0vw auto 0;
  height: 30vw;
  overflow: hidden;
  border-radius: 0 0 90% 90%;
`;

const AuthFormContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
`;

const Header = styled.div`
  position: absolute;
  z-index: 2;
  width: 185px;
  height: 185px;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 0 0 75% 75%;
  background-color: #fff;
`;

const Auth_Signup = (props) => {
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col h-full w-full">
        <CurvedHero className="h-1/3 bg-purple-700 w-full"></CurvedHero>
        <Header
          onClick={() => props.history.push("/")}
          className="text-white flex justify-center items-center cursor-pointer"
        >
          <span className=" bg-white flex justify-center items-end pt-6">
            <img className="" src="/logo.png" style={{ height: "75px" }} />
          </span>
        </Header>
        <AuthFormContainer className="bg-gray-50 h-2/3  w-4/6 rounded mx-auto top-1/4">
          <Signup />
        </AuthFormContainer>
        {/* <div className="h-2/3 w-full">
          <Login />
        </div> */}
      </div>
    </div>
  );
};

const Auth_Login = (props) => {
  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full w-full">
        <CurvedHero className="h-1/3 bg-purple-700 w-full"></CurvedHero>
        <Header
          onClick={() => props.history.push("/")}
          className="text-white flex justify-center items-center cursor-pointer"
        >
          <span className=" bg-white flex justify-center items-end pt-6">
            <img className="" src="/logo.png" style={{ height: "75px" }} />
          </span>
        </Header>
        <AuthFormContainer className="bg-gray-50 h-2/3  w-4/6 rounded mx-auto top-1/4">
          <Login />
        </AuthFormContainer>
      </div>
    </div>
  );
};

function App(props) {
  const { app_state, loading } = useStore();

  if (!app_state.isAuthenticated) {
    return (
      <>
        {loading && <Loading />}

        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Auth_Login {...props} />;
            }}
          />

          <Route
            path="/login"
            render={() => {
              return <Auth_Login {...props} />;
            }}
          />
          <Route
            path="/signup"
            render={() => {
              return <Auth_Signup {...props} />;
            }}
          />
          <Route
            path="/forgot-password"
            render={() => {
              return (
                <div className="h-full w-full">
                  <div className="flex flex-col h-full w-full">
                    <CurvedHero className="h-1/3 bg-purple-700 w-full"></CurvedHero>
                    <Header
                      onClick={() => props.history.push("/")}
                      className="text-white flex justify-center items-center cursor-pointer"
                    >
                      <span className=" bg-white flex justify-center items-end pt-6">
                        <img
                          className=""
                          src="/logo.png"
                          style={{ height: "90px" }}
                        />
                      </span>
                    </Header>
                    <AuthFormContainer className="bg-gray-50 h-2/3  w-4/6 rounded mx-auto top-1/4">
                      <ForgotPassword {...props} />
                    </AuthFormContainer>
                  </div>
                </div>
              );
            }}
          />
          <Route
            path="*"
            render={() => {
              return <Auth_Login {...props} />;
            }}
          />
          <Route
            path="/api-docs"
            render={() => {
              props.history.push("/");
              window.open(
                "https://documenter.getpostman.com/view/8269645/Tzz8qcBw",
                "_blank"
              );
            }}
          />
        </Switch>
        <Footer />
      </>
    );
  }

  return (
    <>
    <TopBar />
    <div className="mt-20">
      {loading && <Loading />}

      
      <Switch>
        <Route exact path="/" component={Home} />

        <Route path="/dashboard" component={Home} />

        <Route path="/phone-book" component={PhoneBook} />
        <Route path="/messages" component={Messages} />
        <Route path="/transactions" component={Transactions} />
        <Route path="/deposit" component={Deposit} />
        <Route path="/profile" component={Profile} />
        <Route path="/settings" component={Settings} />
        <Route path="/contact-us" component={ContactUs} />

        <Route path="/new-message" component={NewMessage} />
        <Route
          path="/api-docs"
          render={() => {
            props.history.push("/");
            window.open(
              "https://documenter.getpostman.com/view/8269645/Tzz8qcBw",
              "_blank"
            );
          }}
        />

        <Route
          path="*"
          render={() => {
            return <div>404 Page</div>;
          }}
        />
      </Switch>
    </div>
    </>
  );
}

export default withRouter(App);
