import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_api_key } from "../services/api";
import { useStore } from "./Context";
import Footer from "./Footer";

const Settings = () => {
  const [apiKey, setApiKey] = useState("");
  const [action, setAction] = useState("");

  const { app_state, setLoading } = useStore();
  const [user, setUser] = useState(app_state.user);

  const actions = {
    getApiKey: "GET_API_KEY",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.getApiKey: {
          setLoading(true);
          get_api_key(app_state.auth_token, user.id)
            .then((res) => {
              setLoading(false);
              setApiKey(res.api_key);
            })
            .catch((err) => {
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <ToastContainer />
      <legend className="uppercase lg:px-10 text-lg font-bold my-4 border-b-2 py-2 text-gray-500">
        API Key
      </legend>

      {!apiKey && (
        <div>
          <button
            className="px-6 py-2 border font-semibold uppercase hover:bg-purple-700 hover:text-white rounded"
            onClick={() => {
              setAction(actions.getApiKey);
            }}
          >
            Generate new API key
          </button>
        </div>
      )}

      {apiKey && (
        <>
          <div className="px-6 py-2 mx-auto">
            <p>
              Copy and save the generated API key some where as you will not be
              able to retrieve it incase you leave or refresh this page.
            </p>
          </div>
          <div className="px-6 py-2 my-4">
            <span className="bg-gray-100 rounded px-10 py-6 font-semibold">
              {apiKey}
            </span>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default Settings;
