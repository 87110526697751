import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deposit, login } from "../services/api";
import { useStore } from "./Context";
import DepositModal from "./DepositModal";
import Footer from "./Footer";

const Deposit = (props) => {
  const { app_state, setAppState, setLoading } = useStore();
  const [data, setData] = useState({});
  const [action, setAction] = useState("");
  const [success, setSuccess] = useState(false);

  const actions = {
    deposit: "DEPOSIT",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.deposit: {
          setLoading(true);
          deposit(app_state.auth_token, data)
            .then((res) => {
              setLoading(false);
              console.log(res);
              setSuccess(res.status);
              //
            })
            .catch((err) => {
              console.log(err);
              setSuccess(false);
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);
              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  if (success) {
    return <DepositModal isOpen={success} setIsOpen={setSuccess} />;
  }

  return (
    <>
      <div className="h-full w-full mx-auto">
        <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">
                Top Up
              </h2>
            </div>

            <ToastContainer />

            <form
              className="mt-8 space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                setAction(actions.deposit);
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div className="my-2">
                  <label
                    htmlFor="phone_number"
                    className="w-full flex flex-row mb-1 font-bold"
                  >
                    Mobile Money number
                  </label>
                  <input
                    id="phone_number"
                    name="phone_number"
                    type="text"
                    required
                    className="appearance-none rounded relative block w-full px-3 py-2 border 
                  border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="e.g 0777777777"
                    autoComplete="off"
                    onChange={(e) => {
                      setData({ ...data, phone_number: e.target.value });
                    }}
                  />
                </div>
                <div className="my-2">
                  <label
                    htmlFor="amount"
                    className="w-full flex flex-row mb-1 font-bold"
                  >
                    Amount
                  </label>
                  <input
                    id="amount"
                    name="amount"
                    type="number"
                    required
                    className="appearance-none rounded relative block w-full px-3 py-2 border
                   border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                   focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="10000"
                    autoComplete="off"
                    onChange={(e) => {
                      setData({ ...data, amount: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="pb-10">
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      fill="none"
                      viewBox="0 0 20 20"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      />
                    </svg>
                  </span>
                  CONTINUE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Deposit);
