import { useEffect, useState } from "react";
import { update_user } from "../services/api";
import curr_format from "../utils/currency_formatter";
import { useStore } from "./Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isObject } from "lodash";
import ProfileUpdateSuccess from "./ProfileUpdateSuccess";

const Profile = () => {
  const { app_state, setAppState, setLoading } = useStore();
  const [user, setUser] = useState(app_state.user);
  const [edit, setEdit] = useState(false);
  const [action, setAction] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const actions = {
    update_user: "UPDATE_USER",
  };

  useEffect(() => {
    if (action) {
      switch (action) {
        case actions.update_user: {
          setLoading(true);
          update_user(app_state.auth_token, user, user.id)
            .then((res) => {
              setLoading(false);
              if (res.status) {
                setUpdateSuccess(true);
              }

              //
            })
            .catch((err) => {
              let message = err.response.data.message;
              let toast_config = {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              };

              if (isObject(message)) {
                let messages = [];
                Object.keys(message).forEach((key) => {
                  messages.push(message[key]);
                });
                //toast(messages.join(' , '));
                toast.error(messages.join(" , "), toast_config);
              } else {
                toast.error(message, toast_config);
              }
              console.log(err.response.data);
              setLoading(false);
            });
        }
        default: {
          setAction(null);
        }
      }
    }
  }, [action]);

  if (updateSuccess) {
    return (
      <ProfileUpdateSuccess
        isOpen={updateSuccess}
        setIsOpen={setUpdateSuccess}
      />
    );
  }

  return (
    <form
      className="mt-8 space-y-6"
      onSubmit={(e) => {
        e.preventDefault();
        setAction(actions.update_user);
      }}
    >
      <ToastContainer />
      <div className="flex mx-auto md:w-2/5">
        <span className="uppercase mr-4 text-gray-500">Balance : </span>
        <span>{curr_format(user.acc_balance)}</span>
      </div>
      <div className="flex flex-row-reverse mx-auto md:w-2/5">
        <button
          type="button"
          className="text-purple-700 rounded px-4 py-1 font-bold border hover:bg-purple-700 hover:text-white border-purple-700 flex justify-center items-center"
          onClick={() => {
            setEdit(true);
          }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </span>
          <span>Edit</span>
        </button>
      </div>
      <div className="rounded-md mx-auto shadow-sm md:w-2/5 bg-gray-50 p-6">
        <div className="flex w-full my-2 justify-between flex-wrap">
          <div className="xs:w-full lg:w-2/5">
            <label htmlFor="firstname" className="xs:w-full">
              First name
            </label>
            <input
              id="firstname"
              name="firstname"
              type="text"
              autoComplete="off"
              required
              className={` xs:w-full appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                      !edit ? "font-bold outline-none " : ""
                    }`}
              placeholder="First name"
              value={user.firstname}
              onChange={(e) => {
                setUser({ ...user, firstname: e.target.value });
              }}
              disabled={!edit}
            />
          </div>

          <div className="xs:w-full xs:my-2 lg:w-2/5">
            <label htmlFor="lastname" className="xs:w-full">
              Last name
            </label>
            <input
              id="lastname"
              name="lastname"
              type="text"
              autoComplete="off"
              required
              className={`xs:w-full appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                      !edit ? "font-bold outline-none " : ""
                    }`}
              placeholder="Last name"
              disabled={!edit}
              value={user.lastname}
              onChange={(e) => {
                setUser({ ...user, lastname: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="flex w-full lg:my-2 justify-between flex-wrap">
          <div className="xs:w-full lg:w-2/5">
            <label htmlFor="email-address" className="xs:w-full">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              className={`xs:w-full appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm font-bold outline-none`}
              placeholder="Email address"
              disabled={true}
              value={user.email}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
            />
          </div>
          <div className="xs:w-full xs:my-2 lg:w-2/5">
            <label htmlFor="mobile_number" className="">
              Mobile number
            </label>
            <input
              id="mobile_number"
              name="mobile_number"
              type="text"
              autoComplete="off"
              required
              className={`xs:w-full appearance-none rounded relative block w-full px-3 py-2 border 
                    border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none 
                    focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                      !edit ? "font-bold outline-none " : ""
                    }`}
              placeholder="+256777777777"
              disabled={!edit}
              value={user.mobile_number}
              onChange={(e) => {
                setUser({ ...user, mobile_number: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="flex w-full lg:my-2 flex-wrap justify-between">
          {edit && (
            <div className="xs:w-full lg:w-2/5">
              <label htmlFor="password" className="xs:w-full">
                New Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                className={`xs:w-full appearance-none rounded relative block w-full px-3 py-2 border
                     border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                     focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                       !edit ? "font-bold outline-none " : ""
                     }`}
                placeholder="Password"
                minLength="6"
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
              />
            </div>
          )}

          {edit && (
            <div className="xs:w-full xs:my-2 lg:w-2/5">
              <label htmlFor="password" className="w-full">
                Confirm Password
              </label>
              <input
                id="confirm_password"
                name="confirm_password"
                type="password"
                autoComplete="off"
                className={`xs:w-full appearance-none rounded relative block w-full px-3 py-2 border
                     border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none 
                     focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                       !edit ? "font-bold outline-none " : ""
                     }`}
                placeholder="Confirm Password"
                minLength="6"
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, confirm_password: e.target.value });
                }}
              />
            </div>
          )}

          <div className="flex flex-row-reverse w-full">
            {edit && (
              <>
                <button
                  type="submit"
                  className="text-white rounded px-4 py-1 font-bold bg-purple-700 hover:bg-purple-600 mt-6"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="rounded px-4 py-1 border border-gray-400 mt-6 mx-2"
                  onClick={() => {
                    setEdit(false);
                  }}
                >
                  CANCEL
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Profile;
